import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { appRoutes } from 'routing';
import { useAuthSlice } from 'store/authSlice';

const hiddenOn = [
  appRoutes.auth.forgotPsw,
  appRoutes.auth.resetPsw,
  appRoutes.journey.personalDetails,
];

export const LoginBtn = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { handleStartLogIn } = useAuthSlice();

  return hiddenOn.includes(location.pathname) ? null : (
    <StyledBtn onClick={() => handleStartLogIn()} data-testid="nav.login" type="button">
      {t('APPB2C.common.nav.ttp')}
    </StyledBtn>
  );
};

const StyledBtn = styled.button`
  transition: box-shadow 0.3s ease-in-out;
  [dir='rtl'] & {
    text-align: right;
  }

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 6px rgba(1, 156, 238, 0.5);
    z-index: 1;
    position: relative;
  }
`;
