import Uppy from '@uppy/core';
import { OrganisationCountryAvailableIdDto } from 'ors-api/mod';
import { ApiErrorsList } from 'ors-ui';
import { getRegFlow, GTM } from 'core';
import { appRoutes, navigateTo } from 'routing';
import { candidate } from '../../candidate';
import { getIdFiles, prepareCandidateDocuments } from '../../api';
import { searchSelect } from '../../searchSelect';
import { AppThunk } from '../..';
import slice from '../idDetailsSlice';
import { deleteAllIdFiles, verifyDocuments, saveCandidateIdDetails } from '../api/api.ors';
import { saveCandidateIdDetailsUkvi } from '../api/api.ukvi';
import { IdDetailsForm } from '../models';
import { UppyFileWithMeta } from '..';
import { isIdValid, isUploadEnabled, isIdTypeAvailable } from './thunks.helpers';
import { E_VISA_ID, E_VISA_PREFIX } from 'ors-utils';

export const loadData = (): AppThunk => async (dispatch, getState) => {
  const forWhom = getState().candidate.forWhom;
  const idTypes = getState().idDetails.idTypes.data;

  const candidateId =
    forWhom === 'myself'
      ? getState().candidate?.userProfile?.id
      : getState().candidate?.ttProfile?.id;

  candidateId && (await dispatch(verifyDocuments(candidateId)));
  candidateId && (await dispatch(removeDocsIfExpired(candidateId, idTypes)));
};

export const removeDocsIfExpired =
  (candidateId: number, idTypes?: OrganisationCountryAvailableIdDto[]): AppThunk =>
  async (dispatch, getState) => {
    const isIol = searchSelect.selectors.isIol();

    const expired = !isIdValid(getState());
    const unavailable = !isIdTypeAvailable(getState());

    if (expired || unavailable) {
      dispatch(candidate.actions.resetId());
      unavailable && dispatch(candidate.actions.resetIdType());
      await dispatch(deleteAllIdFiles(candidateId));
    } else if (isIol || !!isUploadEnabled(idTypes)) {
      await dispatch(getIdFiles(candidateId));

      getState().idDetails.initIdFiles.length === 0 &&
        (await dispatch(prepareCandidateDocuments(candidateId)));
    }
  };

export const proceed =
  (
    form: IdDetailsForm,
    uppy: Uppy.Uppy<Uppy.TypeChecking>,
    selIdType?: OrganisationCountryAvailableIdDto
  ): AppThunk =>
  async (dispatch, getState) => {
    const candidateId = getState().candidate.profile?.id;
    const forWhom = getState().candidate.forWhom;
    const reservationGuid = getState().reservation.reservationGuid!;
    const { idTypes } = getState().idDetails;
    const inChangeMode = getState().registration.changeMode;
    const reg = getState().registration;

    if(form.idType === E_VISA_ID){
      form.issuingAuthority = 'UK';
      form.idNumber = E_VISA_PREFIX + form.idNumber;
    }

    if (!selIdType?.allowToSetDoesntHaveExpiryDate) {
      form.notExpire = false;
    }

    if (form.notExpire) {
      form.expiryDate = '';
    }

    dispatch(slice.actions.setProcessing(true));
    dispatch(slice.actions.setProcessError(undefined));

    dispatch(
      candidate.actions.setNationality({
        id: form.countryOfNationality,
      })
    );
    dispatch(
      candidate.actions.setIdType({
        idType: form.idType,
      })
    );

    const saveIdArgs = {
      organisationCountryId: reg?.inCentreExam?.organisationCountryId ?? -1,
      id: candidateId!,
      idExpiry: form.expiryDate,
      districtExamId: reg?.inCentreExam?.plannedExamId ?? -1,
      idNumber: form.idNumber,
      notExpires: form.notExpire,
      issuingAuthority: form.issuingAuthority,
      idTypeId: form.idType,
      registrationReservationGuid: reservationGuid,
    };

    const { payload, meta } = await dispatch(
      getRegFlow() === 'ukvi'
        ? saveCandidateIdDetailsUkvi(saveIdArgs)
        : saveCandidateIdDetails(saveIdArgs)
    );

    if (meta.requestStatus === 'rejected') {
      return slice.actions.setProcessError(payload as ApiErrorsList);
    }

    if (isUploadEnabled(idTypes.data)) {
      const newFilesCount = uppy
        .getFiles()
        .filter((f: UppyFileWithMeta) => !f.meta.reusedFile).length;

      GTM.update({
        uploadedFiles: newFilesCount === 0 ? 0 : 1,
      });
    } else {
      GTM.update({
        uploadedFiles: -1,
      });
    }

    const idData = {
      idExpiry: form.expiryDate,
      notExpires: form.notExpire,
      idNumber: form.idNumber,
      issuingAuthority: form.issuingAuthority,
      idTypeId: form.idType,
    };

    dispatch(
      forWhom === 'myself'
        ? candidate.actions.profileUpdated(idData)
        : candidate.actions.childProfileUpdated(idData)
    );

    const idTypeName = idTypes.data?.find((t) => t.idTypeId === form.idType)?.alternativeName;
    GTM.update({ idType: idTypeName || '' });
    if (inChangeMode) {
      navigateTo(appRoutes.journey.review);
    } else {
      navigateTo(appRoutes.journey.ttProfile);
    }

    dispatch(slice.actions.setProcessing(false));
  };
