import { AppThunk } from '..';
import { CreateRegistrationFromReservationRequest, MarketingQuestionAnswer } from 'ors-api/iol';
import { postDataLayer } from 'core/analytics';
import { createRegistrationIol } from './api.iol';
import { isUolContext } from '../reservation/selectors';
import { AFFILIATION_PREMIUM_CODE_KEY, CookieManager } from 'ors-utils';

export const registerIol =
  (aiTrainConsentGiven = false): AppThunk =>
  async (dispatch, getState) => {
    const regId = getState().registration.registrationId.data;
    if (regId) {
      return regId;
    }

    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const bookingId = getState().reservation.reservationGuid ?? '';
    const { answers, profile } = getState().candidate;
    const { idDetailsIol } = getState().candidate.iol;
    const { productId } = getState().reservation;
    const { iolExam } = getState().registration;

    const isUol = isUolContext(getState());

    const marketingQuestionAnswers: MarketingQuestionAnswer[] =
      answers.data?.answers?.map((ans) =>
        ans.marketingQuestionShortCode === 'CountryOfNationality'
          ? idDetailsIol?.countryOfNationality
            ? {
                marketingQuestionOptionId: Number(idDetailsIol.countryOfNationality),
                marketingQuestionId: ans.marketingQuestionId,
                openAnswerText: ans.openAnswerText,
              }
            : {
                marketingQuestionOptionId: ans.marketingQuestionOptionId,
                marketingQuestionId: ans.marketingQuestionId,
                openAnswerText: ans.openAnswerText,
              }
          : {
              marketingQuestionOptionId: ans.marketingQuestionOptionId,
              marketingQuestionId: ans.marketingQuestionId,
              openAnswerText: ans.openAnswerText,
            }
      ) ?? [];

    const registrationRequest: CreateRegistrationFromReservationRequest = {
      bookingId,
      timeZoneId,
      marketingQuestionAnswers,
      aiConsentGiven: true,
      aiTrainConsentGiven,
      hasBiometricDocumentWithRfid: isUol,
      idDetails: {
        idTypeId: idDetailsIol?.idType ?? -1,
        expiryDate: idDetailsIol?.expiryDate ?? '',
        number: idDetailsIol?.idNumber,
        issuingAuthority: idDetailsIol?.issuingAuthority,
      },
      addressDetails: {
        countryId: profile?.countryId ?? -1,
        streetAddress1: profile?.addressLine1,
        streetAddress2: profile?.addressLine2,
        streetAddress3: profile?.addressLine3,
        streetAddress4: profile?.addressLine4,
        postCode: profile?.postCode,
        town: profile?.town,
        territoryId: undefined,
      },
      personalDetails: {
        dateOfBirth: profile?.dob ?? '',
        firstName: profile?.firstName,
        surName: profile?.surname,
        mobile: profile?.mobile,
        telephone: undefined,
        genderId: profile?.genderId ?? -1,
        titleId: profile?.titleId ?? -1,
      },
      productId,
      expectedExamPrice: iolExam?.examFee && {
        amount: iolExam.examFee.amount,
        currencyCode: iolExam.examFee.currency,
      },
    };

    const removeAffiliationPremiumCodeCookie = () => {
      CookieManager.eraseCookie(AFFILIATION_PREMIUM_CODE_KEY);
    };

    const { payload } = await dispatch(createRegistrationIol(registrationRequest)).then(
      (registration) => {
        return registration;
      }
    );

    if (payload && 'error' in payload) return;

    removeAffiliationPremiumCodeCookie();

    // TODO: https://jira.britishcouncil.org/browse/ORSNBC-1107 - the same for IOL?
    // GTM.update({ dataLayerCorrelationId: payload.reference });

    postDataLayer();
  };
