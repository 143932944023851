export const appRoutes = {
  /** Welcome / Start page */
  root: '/',
  notFound: '/404',
  unknownCountry: '/unknown-country',
  underConstruction: '/under-construction',
  incorrectOrganisation: '/incorrect-org',
  /** React Router V6 no longer supports optional parameters, using separate routes with a single component is advised */
  terms: {
    index: '/terms-and-conditions/*',
    indexUkvi: '/ukvi/terms-and-conditions/*',
    shortCode: '/:shortCode',
    organisationCountryId: '/:shortCode/:organisationCountryId',
    version: '/:shortCode/:organisationCountryId/:version',
    /** Page where user goes from email to download terms and conditions */
    download: {
      index: '/terms-and-conditions-pdf/*',
      indexUkvi: '/ukvi/terms-and-conditions-pdf/*',
      shortCode: '/:shortCode',
      organisationCountryId: '/:shortCode/:organisationCountryId',
      version: '/:shortCode/:organisationCountryId/:version',
    },
  },
  auth: {
    /** Page where user can start reset password process */
    forgotPsw: '/forgot-password',
    /** Page where user goes from email to set up the new password */
    resetPsw: '/reset-password',
    changePsw: '/change-password',
    /** Callback after Identity Server login */
    callback: '/callback',
  },
  utils: {
    /** Scatter page for starting registration using a link from an Agent */
    deepLink: '/register-for-agent/:token',
    /** Page where user can choose between IELTS Online and IELTS in a test centre */
    chooseTest: '/choose-test',
    /** Page where it is verified if the user country has access to IELTS Online */
    onlineExamChoose: 'online-exam-choose',
  },
  search: {
    /** UKVI start page with links to UKVI and Life skills exams */
    ukvi: 'ukvi',
    /** Page where user can see calendar and available dates for IELTS in a test centre */
    findTest: 'find-test',
    /** Page where user can see actual available exams */
    bookTest: 'book-test',
    /** Page where user can change Speaking for a given exam */
    testDetails: 'test-details',
    /** Page where user can choose between IELTS Online for UKVI and IELTS for UKVI in a test centre */
    chooseTest: 'choose-test',
    /** UKVI on request has been created */
    onRequestCreated: 'on-request/created',
  },
  journey: {
    /** Step 1) Personal details of main candidate (account holder candidate) */
    personalDetails: 'personal-details',
    /** Step 1a (optional)) Personal details of Child/minor candidate (when account holder chose Child) */
    childDetails: 'personal-details/child',
    /** Step 2) Where user provides his ID details + ID upload */
    idDetails: 'id-details',
    /** Step 3) Marketing questions */
    ttProfile: 'tt-profile',
    /** Step 4) Review registration details just before payment */
    review: 'review',
    /** Page placeholder for loading payment status */
    finishPayment: 'online-payment/finish/',
    /** Last page where user can see status of the registration after booking */
    bookingComplete: 'booking-complete/:type/:registrationId/:paymentId',
    /** Hidden user registration path for Ielts Ready member */
    ieltsReadyReg: '/ielts-ready-registration',
    /** Component Page that redirects to correct ielts ready page */
    ieltsReadyRedirect: '/ielts-ready-redirect',
  },
  legacy: {
    /** Legacy paths for registration using old URLs. Will be removed when we are ready we can use flow type all the time. */
    search: {
      testChooserCountry: '/test-chooser/:country',
      testChooser: '/test-chooser',
      landingPageGlobal: '/landing-page/global/:organisation',
      landingPageOrganisation: '/landing-page/:country/:organisation',
      landingPage: '/landing-page/:country',
      findTest: '/find-test',
      bookTest: '/book-test',
      testDetails: '/test-details',
    },
    journey: {
      personalDetails: '/personal-details',
      childDetails: '/personal-details/child',
      idDetails: '/id-details',
      ttProfile: '/tt-profile',
      review: '/review',
      finishPayment: '/online-payment/finish/',
      bookingComplete: '/booking-complete/:type/:registrationId',
      rebook: '/rebook/:regId',
    },
    testTakerPortal: {
      root: '/tests-and-results',
      testDetail: '/test-details/*',
      myAccount: '/my-account',
      preparation: '/ttp/preparation',
    },
  },
};
