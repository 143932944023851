import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { MarketingQuestionAnswerDto } from 'ors-api/iol';

import {
  CandidateMarketingQuestionAnswer,
  CandidateState,
  ValidCandidateMarketingQuestionAnswerCount,
} from '.';
import { getVerifiedDetailsIol } from '../api.iol';
import { apiExtraReducer } from '../_common/apiExtraReducer';

/** Map answers from IOL API to match IC answer's format */
const mapIolAnswers = (
  answers?: MarketingQuestionAnswerDto[]
): CandidateMarketingQuestionAnswer => {
  const data =
    answers?.map((a) => {
      const {
        marketingQuestionId,
        marketingQuestionOptionId,
        marketingQuestionShortCode,
        openAnswerText,
      } = a;
      return {
        marketingQuestionOptionId,
        marketingQuestionId,
        marketingQuestionShortCode,
        id: marketingQuestionOptionId,
        registrationIeltsSpecificId: marketingQuestionOptionId,
        openAnswerText,
      };
    }) ?? [];
  return {
    answers: data,
    isValid: data.length === ValidCandidateMarketingQuestionAnswerCount,
  };
};

export const extraReducersIol = (builder: ActionReducerMapBuilder<CandidateState>) => {
  /** Load Verified Details for IOL */
  apiExtraReducer(builder, getVerifiedDetailsIol, 'answers', {
    transform: (data) => mapIolAnswers(data.marketingDetails),
  });
};
