import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleLS } from '@britishcouncil/react-common';
import { getDraftProcessUrl } from 'ors-utils';
import { NewTermsAccount, TimesUpModal, selectedCountryLanguageSettings } from 'ors-ui';

import { useSelector, GTM, useLocale } from 'core';
import slice from 'store/authSlice';
import { reservation } from 'store/reservation';
import { FullCandidatePersonalDetails } from 'store/candidate';
import { language } from 'store/language';
import { LogoutModal } from '../modals/LogoutModal';
import { LanguageOptionsModal } from './LanguageOptionsModal/LanguageOptionsModal';

export const CommonModals: FC = () => {
  const dispatch = useDispatch();

  const { showTimeUpModal } = useSelector((s) => s.reservation);
  const { profile } = useSelector((s) => s.candidate);
  const { isLoggedIn, hasJustLoggedIn } = useSelector((s) => s.auth);
  const organisationDetails = useSelector((s) => s.organisationCountry.organisationDetails);
  const { showLanguageOptionsModal } = useSelector((s) => s.language);
  const { locale } = useLocale();
  const { activeCountry } = useSelector((state) => state.searchSelect);

  const accountTermsAndConditionsShortCode =
    organisationDetails.data?.accountTermsAndConditions?.shortCode;

  useEffect(() => {
    const profileLS = SimpleLS.read<FullCandidatePersonalDetails>('USER_PROFILE');
    setTimeout(() => {
      /** When user gets back to CJ from the Draft Process, they are sometise blocked by logInProcessing flag */
      dispatch(slice.actions.setLoadingProcessing(false));

      if (profileLS?.needToVerifyDraftProcess ?? profile?.needToVerifyDraftProcess) {
        const currentUrl = window.location.pathname;
        window.location.pathname = getDraftProcessUrl(currentUrl);
      }
    }, 100);
  }, [dispatch, profile?.needToVerifyDraftProcess]);

  useEffect(() => {
    const { isCurrentLangAvailable } = selectedCountryLanguageSettings(
      locale,
      activeCountry?.additionalLanguages
    );

    !isCurrentLangAvailable && dispatch(language.actions.setShowLanguageOptionsModal(true));
  }, [activeCountry]);

  return (
    <>
      <TimesUpModal
        showModal={showTimeUpModal}
        onClose={() => dispatch(reservation.thunksCommon.letReservationGo())}
        onHide={() => GTM.trackModal('Hide', 'timesup-modal')}
        onShow={() => GTM.trackModal('Show', 'timesup-modal')}
      />

      <LogoutModal />

      <NewTermsAccount
        enableAccountTermsCheck={
          !organisationDetails.isLoading &&
          isLoggedIn &&
          !!accountTermsAndConditionsShortCode &&
          !hasJustLoggedIn
        }
        accountTermsAndConditionsShortCode={accountTermsAndConditionsShortCode}
      />

      <LanguageOptionsModal
        showModal={showLanguageOptionsModal}
        countryName={activeCountry?.name}
        additionalLanguages={activeCountry?.additionalLanguages}
      />
    </>
  );
};
