import { getUrlSearchParam } from '@britishcouncil/react-common';
import { RootState } from '..';
import { getRegFlow } from 'core';
import { appRoutes } from 'routing';

/** Page "/find-test" */
export function validateState(state: RootState): true | string {
  const examType = state.searchSelect.examType ?? getUrlSearchParam('examType');
  if (!examType) return '/';

  if (getRegFlow() === 'iol' && !state.searchSelect.ieltsProduct) return '/';

  return true;
}

/** Page "/choose-test" */
export function validateChooseTestPage(state: RootState): true | string {
  const { examType, iolAvailable } = state.searchSelect;
  const { deeplinkToken } = state.invitation;
  const { organisationAlias } = state.organisationCountry;

  if (deeplinkToken || organisationAlias) return appRoutes.root;

  if (iolAvailable.data === false || !examType) return '/';

  return true;
}

/** Page "/online-exam-choose" */
export function validateOnlineExamChoosePage(state: RootState): true | string {
  const examType = state.searchSelect.examType ?? getUrlSearchParam('examType');

  if (!examType) return '/';

  return true;
}
