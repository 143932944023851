import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorDetailed } from '@britishcouncil/react-common';
import { FeatureFlagsDto } from 'ors-api/mod';
import { countryIsoCode2 } from 'ors-utils';

import { GTM } from 'core';
import { refreshFeatureFlags } from './api';
import { AppThunk } from '.';

const sliceName = 'featureFlags';

export const defaultFeatureFlags: FeatureFlagsDto = {
  isOsrEnabled: false,
  isLocaliseEnabled: false,
  isCustomerIdentityEnabled: false,
  isReadyMemberIdentityEnabled: false,
  isCustomerIdentityUsaEnabled: false,
  isReadyMemberIdentityUsaEnabled: false,
  isMapViewEnabled: false,
  isComplaintFormEnabled: false,
  isAccessibilityInfoEnabled: false,
  isUkviCancellationRequestEnabled: false,
  isFraudDetectionEnabled: false,
  isModernisedPreRegisterEnabled: false,
  isModernisedRegisterEnabled: false,
};

const initialState: FeatureFlagsState = {
  features: defaultFeatureFlags,
  loadingFeatureFlags: false,
};

const slice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    updatedAbTests(state, action: PayloadAction<AbTests>) {
      state.abTests = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshFeatureFlags.pending, (state) => {
        state.loadingFeatureFlags = true;
        state.loadingFeatureFlagsErr = undefined;
      })
      .addCase(refreshFeatureFlags.fulfilled, (state, action: PayloadAction<FeatureFlagsDto>) => {
        state.features = action.payload;
        state.loadingFeatureFlags = false;
      })
      .addCase(
        refreshFeatureFlags.rejected,
        (state, action: PayloadAction<ApiErrorDetailed | undefined>) => {
          state.loadingFeatureFlags = false;
          state.loadingFeatureFlagsErr = action.payload;
        }
      );
  },
});

export interface FeatureFlagsState {
  features: FeatureFlagsDto;
  loadingFeatureFlags: boolean;
  loadingFeatureFlagsErr?: ApiErrorDetailed;
  abTests?: AbTests;
}

export interface AbTests {
  countryCode: string | null;
}

export default slice;

function isObjStructureSame(objA: object, objB: object): boolean {
  return Object.keys(objA).sort().join('_') === Object.keys(objB).sort().join('_');
}

export const getCountryForAbTests = (countryCode: string) => {
  if (countryCode === countryIsoCode2.FRANCE || countryCode === countryIsoCode2.SPAIN) {
    return countryCode;
  } else {
    return null;
  }
};

/** Function that initializes or refreshes AbTests as necessary */
export const refreshAbTests =
  (countryCode: string): AppThunk =>
  async (dispatch, getState) => {
    const ff = getState().featureFlags;

    /** Set this flag to "false" when there is no active A/B test */
    const isAbTestActive = true;

    const abTests: AbTests = {
      countryCode: getCountryForAbTests(countryCode),
    };

    if (ff.abTests && isAbTestActive && !isObjStructureSame(ff.abTests, abTests)) {
      const refreshedAbTests = Object.assign({}, abTests, ff.abTests);
      dispatch(slice.actions.updatedAbTests(refreshedAbTests));
    }

    if (ff.abTests && isAbTestActive) {
      GTM.updateAbTests(ff.abTests);
      return;
    }

    if (isAbTestActive) {
      dispatch(slice.actions.updatedAbTests(abTests));
      GTM.updateAbTests(abTests);
    }
  };
